import { Flex, ITheTableCell, TheTable } from "components/common";
import CurrencyNumbers from "components/wrewards-ui-kit/CurrencyNumbers";
import styled, { css } from "styled-components";
import { formatNumber } from "./utils/formatNumbers";
import React from "react";
import { IDtoLeaderboardUser } from "interfaces";

export type LeaderboardPrizeTableItem = {
  prizeNumber: number;
  amount?: number;
  winner?: IDtoLeaderboardUser;
};

export type ILeaderboardUserTableOptions = {};

const RaffleLeaderboardPrizeTable: React.FC<{ data: LeaderboardPrizeTableItem[] }> = ({
  data = [],
}) => {
  const rows = data;

  const showWagered = data.some((i) => !!i.winner);

  const setup = React.useMemo(
    () => [
      {
        $key: "place-displayName",
        render: (props: ITheTableCell<LeaderboardPrizeTableItem, ILeaderboardUserTableOptions>) => {
          console.log("props.row", props.row);

          const _: string | undefined = props.row?.winner?.displayName;
          const place = props.row?.winner?.position;
          return (
            <Flex gap={12}>
              <RowId className={`place place-${place}`}>
                <span className="text">{place}</span>
              </RowId>
              <User>{_ ? _ : "-"}</User>
            </Flex>
          );
        },
        title: <Title style={{ textAlign: "left" }}>Place & User</Title>,
      },
      ...(showWagered
        ? [
            {
              $key: "value",
              render: (
                props: ITheTableCell<LeaderboardPrizeTableItem, ILeaderboardUserTableOptions>
              ) => {
                return (
                  <Flex width="100%" justify="center">
                    <CurrencyNumbers icon="USD">
                      {formatNumber(
                        props.row?.winner?.wageredTotal ? +props.row?.winner.wageredTotal : 0
                      )}
                    </CurrencyNumbers>
                  </Flex>
                );
              },
              title: <Title style={{ textAlign: "center" }}>Wagered</Title>,
            },
          ]
        : []),

      {
        $key: "prize",
        render: (props: ITheTableCell<LeaderboardPrizeTableItem, ILeaderboardUserTableOptions>) => {
          const row = props.row;

          return (
            <Flex justify="flex-end" align="center" gap={8}>
              <CurrencyNumbers
                icon="USD"
                // style={row?.prizeColor ? { color: row.prizeColor } : {}}
              >
                {formatNumber(row?.amount ? +row.amount : 0)}
              </CurrencyNumbers>
              {/* {props.row?.winner?.proof && <LeaderboardRaffleVerifyModalButton proof={props.row.winner?.proof} />} */}
            </Flex>
          );
        },
        title: <Title style={{ textAlign: "right" }}>Prize</Title>,
      },
    ],
    []
  );
  const rowProps = ({ row }: { row: any | null }) => {
    if (!row) {
      return {
        styles: css`
          border: none;
        `,
      };
    }
    if (row.prizeNumber === 1) {
      return {
        styles: css`
          background: linear-gradient(90deg, rgba(226, 192, 86, 0.2) 0%, rgba(0, 0, 0, 0) 100%);
          border: none;
          background-repeat: no-repeat;
          overflow: hidden;
        `,
      };
    }
    if (row.prizeNumber === 2) {
      return {
        styles: css`
          background: linear-gradient(
            90deg,
            rgba(198, 202, 216, 0.2) 0%,
            rgba(198, 202, 216, 0) 100%
          );
          background-repeat: no-repeat;
          border: none;
          overflow: hidden;
        `,
      };
    }
    if (row.prizeNumber === 3) {
      return {
        styles: css`
          background: linear-gradient(
            90deg,
            rgba(224, 171, 126, 0.2) 0%,
            rgba(224, 171, 126, 0) 100%
          );
          background-repeat: no-repeat;
          border: none;
          overflow: hidden;
        `,
      };
    }

    return {};
  };

  return (
    <Flex column gap={16} align="center">
      <Title style={{ textAlign: "center" }}>Prizes</Title>
      <TheTable<LeaderboardPrizeTableItem, ILeaderboardUserTableOptions>
        $keys={["place-displayName", "value", "prize"]}
        $setup={setup}
        rows={rows}
        // CellProps={cellProps}
        RowProps={rowProps}
        // @ts-ignore
        boxStyles={{ width: "100%" }}
      />
    </Flex>
  );
};

const RowId = styled.div<{ rowColor?: string }>`
  box-sizing: border-box;
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
  position: relative;
  width: 20px;
  position: relative;

  &.place-1 {
    &::after {
      content: "";
      position: absolute;
      z-index: 0;
      width: 20px;
      height: 20px;
      top: -2px;
      transform: rotate(45deg);
      border-radius: 4px;
      background: rgba(226, 192, 86, 0.25);
      border: 2px solid #e2c056;
    }
  }
  &.place-2 {
    &::after {
      content: "";
      position: absolute;
      z-index: 0;
      width: 20px;
      height: 20px;
      top: -2px;
      transform: rotate(45deg);
      border-radius: 4px;
      background: rgba(198, 202, 216, 0.25);
      border: 2px solid #c6cad8;
    }

    .text {
      color: #e2c056;
    }
  }
  &.place-3 {
    &::after {
      content: "";
      position: absolute;
      z-index: 0;
      width: 20px;
      height: 20px;
      top: -2px;
      transform: rotate(45deg);
      border-radius: 4px;
      background: rgba(224, 171, 126, 0.25);
      border: 2px solid #e0ab7e;
    }
  }

  .text {
    font-size: 14px;
    font-style: italic;
    font-weight: 800;
    position: relative;
    z-index: 2;
    background: linear-gradient(180deg, #e9f0ff 0%, #bdccfa 100%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    padding: 0 2px;
  }
`;

const Title = styled.div`
  color: rgba(210, 234, 255, 0.75);
  color: var(--daylight-daylight-main, #d5e0f5);
  font-family: var(--font-family-exo);
  font-size: 14px;
  font-style: italic;
  font-weight: 800;
  line-height: normal;
  text-shadow: 0px 1px 1px rgba(0, 0, 0, 0.25);
  text-transform: uppercase;
`;

const User = styled.div`
  color: #d2eaff;
  font-family: var(--font-family-exo);
  font-size: 16px;
  font-style: italic;
  font-weight: 800;
  text-transform: uppercase;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  text-shadow: 0px 1px 1px rgba(0, 0, 0, 0.25);

  &::before {
    content: "";
    /* position: absolute; */
    z-index: 0;
    width: 2px;
    height: 2px;
    background: rgba(200, 215, 245, 0.25);
    transform: rotate(45deg);
    border-radius: 4px;
    border: 2px solid var(--daylight-daylight-75, rgba(200, 215, 245, 0.75));
  }
`;

export default RaffleLeaderboardPrizeTable;
