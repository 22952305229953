import React, { useState } from "react";
import styled, { css } from "styled-components";
import {
  $stylesReset,
  IStyles,
  Ident,
  LeaderboardWinnerCardList,
  LeaderboardEndsIn,
  LeaderboardUsersTable,
} from "components";
import {
  IDtoLeaderboard,
  IDtoLeaderboardUser,
  LeaderboardPrizeSelectionType,
  TheLeaderboardSourceKeys,
} from "interfaces";
import { Button, ButtonBlue, Flex, NoEntitiesText } from "components/common";
import { useRouter } from "next/router";
import { PRIZES_OUTSIDE_ADDITIONAL_PRIZES_COUNT } from "helpers/constants";
import { scrollToElement } from "helpers/scrollToElement";
import { Fade } from "react-awesome-reveal";
import LeaderboardReferralBlock from "./LeaderboardReferralBlock";
import LeaderboardDisclaimer from "./LeaderboardDisclaimer";

import { formatNumber } from "./utils/formatNumbers";
import { useAppContext } from "contexts";
import { Spinner } from "@chakra-ui/react";
import { animated, useSpring } from "@react-spring/web";
import LeaderboardProviderSwitcher from "./LeaderboardProviderSwitcher";
import LeaderboardProviderBG from "./LeaderboardProviderBG";
import WrewardsButton from "components/wrewards-ui-kit/Button";
import Image from "next/image";
import Close from "/public/images/icons/xmark.svg";
import Finish from "/public/images/icons/finish.svg";
import LeaderboardRafflePrizeGrid from "./LeaderboardRafflePrizeGrid";
import { DateTime } from "luxon";

const providers = {
  [TheLeaderboardSourceKeys.gamdom]: "/images/leaderboard/providers/gamdom.png",
  [TheLeaderboardSourceKeys.wrewards]: "/images/leaderboard/providers/wrewards.png",
  [TheLeaderboardSourceKeys.cases]: "/images/AllBonuses/provider-logos/cases.svg",
};

export interface ILeaderboardWinnersBlock {
  title?: string;
  switcher: string;
  setSwitcher: (switcher: string) => void;
  setTake?: React.Dispatch<React.SetStateAction<number>>;
  leaderboard?: IDtoLeaderboard | null;
  leaderboardWinners: IDtoLeaderboardUser[];
  setLeaderboardUsers?: React.Dispatch<React.SetStateAction<IDtoLeaderboardUser[]>>;
  leaderboardUsers: IDtoLeaderboardUser[];
  showBlockHeader?: boolean;
  showSwitcher?: boolean;
  isEnded?: boolean;
  take?: number;
  showLeaderboardEndsIn?: boolean;
  showLeaderboardUserList?: boolean;
  showIntervalUpdate?: boolean;
  showActiveLeaderboardButton?: boolean;
  showTableSpinner?: boolean;
  currentUserPosition?: IDtoLeaderboardUser;
}

export const LeaderboardWinnersBlock: React.FC<ILeaderboardWinnersBlock> = ({
  switcher = TheLeaderboardSourceKeys.gamdom,
  setSwitcher,
  leaderboard,
  isEnded,
  leaderboardWinners,
  leaderboardUsers,
  currentUserPosition,
  setTake,
  take,
  showBlockHeader = false,
  showSwitcher = true,
  showLeaderboardEndsIn = false,
  showLeaderboardUserList = false,
  showActiveLeaderboardButton = false,
  showTableSpinner,
}): React.ReactElement => {
  const { isFeatureEnabled } = useAppContext();
  const router = useRouter();

  const randomPrizes = leaderboard?.randomPrizes;
  const randomPrizesCount = randomPrizes?.length;
  const randomPrizeThreshold = leaderboard?.randomPrizeThreshold;
  const maxPublicUsersCount = leaderboard?.maxPublicUsersCount;

  const additionalPrizesCount =
    (leaderboard?.additionalPrizes?.length || 0) + PRIZES_OUTSIDE_ADDITIONAL_PRIZES_COUNT;

  const maxPublicUsersToDisplay =
    (maxPublicUsersCount || 0) > additionalPrizesCount
      ? maxPublicUsersCount
      : additionalPrizesCount;

  const prizeSum =
    randomPrizes?.reduce(
      (sum: number, prize: { amount: number; prizeNumber: number }) => sum + prize.amount,
      0
    ) || 0;

  const onSeeMore = () => {
    if (setTake && maxPublicUsersToDisplay) {
      setTake(maxPublicUsersToDisplay);
    }
  };

  const leaderboardUsersTableBoxItemId = "leaderboardUsersTableBoxItem";

  const collapseWinners = () => {
    if (setTake) {
      setTake(10);
      scrollToElement(leaderboardUsersTableBoxItemId);
    }
  };

  const isAllWinnersShown = take && leaderboard?.maxPublicUsersCount ? take > 10 : false;

  const toShowCollapseButton = (leaderboard?.maxPublicUsersCount ?? 0) >= 10;
  console.log(leaderboard);

  return (
    <>
      <WrapperBG>
        <LeaderboardProviderBG
          casinoProvider={switcher}
          type={leaderboard?.prizeSelectionType ?? ""}
        />

        <Box casinoProvider={switcher} className="container">
          <Ident height={16} />

          {showBlockHeader ? (
            <div
              style={{ display: "flex", flexDirection: "column", alignItems: "center", gap: 16 }}
            >
              {leaderboard?.prizeSelectionType === LeaderboardPrizeSelectionType.RAFFLE ? (
                <Flex align="center" gap={16} style={{ filter: "drop-shadow(0px 0px 10px #000)" }}>
                  <div>
                    <Image src={providers[switcher]} width={220} height={40} alt="" />
                  </div>
                  <div style={{ color: "#ABBADB", opacity: 0.2 }}>
                    <Close width={40} height={40} />
                  </div>
                  <div>
                    <Image
                      src={providers[TheLeaderboardSourceKeys.wrewards]}
                      width={220}
                      height={40}
                      alt=""
                    />
                  </div>
                </Flex>
              ) : null}

              {/* <Ident height={24} /> */}
              {/* <BoxItem>
                <Image
                  style={{ marginTop: 2 }}
                  src="/images/HeaderComponent/logo.svg"
                  height={32}
                  width={160}
                  alt="image"
                />
              </BoxItem> */}
              {leaderboard?.prizeSelectionType === LeaderboardPrizeSelectionType.RAFFLE ? (
                <>
                  {leaderboard ? (
                    <Fade direction="left" delay={330} triggerOnce>
                      <TotalPrize>
                        <b data-content={"$"}>$</b>
                        <AnimatedNumber number={leaderboard?.totalPrizePool ?? 0} />
                      </TotalPrize>
                    </Fade>
                  ) : null}
                  <TextGradient>
                    <b data-content="Raffle Leaderboard">Raffle Leaderboard</b>
                  </TextGradient>
                  <Ident height={16} />
                  {leaderboard.state === "FINISHED" && <FinishTile date={leaderboard.endDate} />}
                </>
              ) : (
                <>
                  <TextGradient>
                    <span>Leaderboard</span>
                  </TextGradient>
                  {leaderboard ? (
                    <Fade direction="left" delay={330} triggerOnce>
                      <Subtitle>Total Prize pool</Subtitle>
                      <TotalPrize>
                        <b data-content={"$"}>$</b>
                        <AnimatedNumber number={leaderboard?.totalPrizePool ?? 0} />
                        {/* {formatNumber()} */}
                      </TotalPrize>
                    </Fade>
                  ) : null}
                  <Ident height={16} />
                </>
              )}
            </div>
          ) : null}
          {/* <Ident height={40} /> */}

          <BoxItem>
            {showSwitcher ? (
              <>
                <Fade direction="right" delay={330} triggerOnce>
                  {/* <SlideSwitcher
                    title={<>CHOOSE LEADERBOARD</>}
                    value={switcher}
                    onChange={setSwitcher}
                    centerSwitcher={true}
                    isGridView={true}
                    styles={css`
                      margin: 0 auto;
                    `}
                  /> */}
                </Fade>
              </>
            ) : null}
          </BoxItem>
          <BoxItem className="container">
            {showSwitcher ? (
              <>
                <Fade direction="right" delay={330} triggerOnce>
                  <LeaderboardProviderSwitcher value={switcher} onChange={setSwitcher} />
                </Fade>
              </>
            ) : null}
          </BoxItem>
          <Ident height={37} />

          <BoxItem>
            <LeaderboardDisclaimer
              casinoProvider={switcher}
              type={leaderboard?.prizeSelectionType ?? LeaderboardPrizeSelectionType.TIERED}
              ticketRate={leaderboard?.ticketRate ?? 1}
            />
          </BoxItem>
          {leaderboard?.prizeSelectionType === LeaderboardPrizeSelectionType.RAFFLE ? (
            <LeaderboardRafflePrizeGrid leaderboard={leaderboard} />
          ) : (
            <BoxItem>
              {leaderboard && leaderboardWinners?.length ? (
                <LeaderboardWinnerCardList
                  leaderboard={leaderboard}
                  leaderboardUsers={leaderboardWinners}
                  customWinnerRewardText={
                    switcher === TheLeaderboardSourceKeys.wrewards &&
                    isFeatureEnabled("ENABLE_CUSTOM_WINNER_REWARD") ? (
                      <CustomRewards>
                        <small>Spend my</small>
                        <span>$30,000 </span>
                        <small>(keep 25%) video</small>
                      </CustomRewards>
                    ) : null
                  }
                />
              ) : (
                // hide coming soon for hypedrop when disaclaimer shown
                <>
                  {switcher !== TheLeaderboardSourceKeys.hypedrop ? (
                    <NoEntitiesText>Coming soon</NoEntitiesText>
                  ) : null}
                </>
              )}
            </BoxItem>
          )}

          <Ident height={40} />
          {showLeaderboardEndsIn && leaderboard ? (
            <BoxItem styles={cssList}>
              <LeaderboardEndsIn
                value={leaderboard?.endDate ?? null}
                start={leaderboard.startDate ?? null}
                variant={leaderboard.prizeSelectionType === LeaderboardPrizeSelectionType.RAFFLE ? 'v2' : 'v1'}
              />
              <Ident height={38} />
            </BoxItem>
          ) : null}
          {showLeaderboardUserList && leaderboard && leaderboardUsers?.length ? (
            <BoxItem id={leaderboardUsersTableBoxItemId} styles={cssList}>
              <LeaderboardUsersTable
                $keys={
                  leaderboard?.prizeSelectionType === LeaderboardPrizeSelectionType.RAFFLE
                    ? ["place-displayName", "value", "tickets"]
                    : ["#", "displayName", "value", "prize"]
                }
                leaderboard={leaderboard}
                leaderboardUsers={leaderboardUsers}
                usersSkip={3}
                usersTake={12}
                ticketRate={leaderboard?.ticketRate}
              />
              {currentUserPosition && switcher === TheLeaderboardSourceKeys.wrewards ? (
                <LeaderboardUsersTable
                  $keys={
                    leaderboard?.prizeSelectionType === LeaderboardPrizeSelectionType.RAFFLE
                      ? ["place-displayName", "value", "tickets"]
                      : ["#", "displayName", "value", "prize"]
                  }
                  leaderboard={leaderboard}
                  leaderboardUsers={[currentUserPosition]}
                  isSingleUser
                  ticketRate={leaderboard?.ticketRate}
                />
              ) : null}
              {/* TODO fix more item button */}
              <FlexWrapper>
                {showTableSpinner ? <Spinner size={"xl"} /> : null}
                {toShowCollapseButton ? (
                  <WrewardsButton
                    variant="game-action-gray"
                    onClick={() => {
                      if (isAllWinnersShown) {
                        collapseWinners();
                      } else {
                        onSeeMore();
                      }
                    }}
                  >
                    <Image
                      src="/images/LeaderboardComponent/eye.png"
                      alt="plus"
                      width={20}
                      height={20}
                    />
                    <span>{isAllWinnersShown ? "Hide" : "Show More"}</span>
                  </WrewardsButton>
                ) : null}
              </FlexWrapper>
            </BoxItem>
          ) : showLeaderboardUserList ? (
            <Ident height={70} />
          ) : null}

          {showActiveLeaderboardButton ? (
            <>
              <Ident height={27} />
              <BoxItem>
                <ExploreText>Explore the entire</ExploreText>
                <br />
                <ExploreText>leaderboard and potential prizes!</ExploreText>
              </BoxItem>
              <Ident height={24} />
              <BoxItem></BoxItem>
              <Flex width="100%" justify="center">
                <Button
                  isDark
                  style={{ height: 50, width: 150, whiteSpace: "nowrap" }}
                  onClick={(event: React.MouseEvent) => {
                    event.preventDefault();
                    router.push("/leaderboards/active");
                  }}
                >
                  <ButtonBlue isDark style={{ whiteSpace: "nowrap" }} transparent>
                    Open leaderboard
                  </ButtonBlue>
                </Button>
              </Flex>
              <Ident height={20} />
            </>
          ) : null}
          <Ident height={23} />
        </Box>
      </WrapperBG>
      {/* {!isEnded && randomPrizes && randomPrizesCount ? (
        <LeaderboardReferralBlock
          casinoProvider={switcher}
          prizeSum={prizeSum}
          randomWinnersCount={randomPrizesCount}
          randomPrizeThreshold={randomPrizeThreshold}
          randomPrizes={randomPrizes}
          additionalPrizesCount={additionalPrizesCount}
        />
      ) : null} */}
    </>
  );
};

const FinishTile: React.FC<{ date: string }> = ({ date }) => {
  return (
    <TileWrapper>
      <Flex align="center" gap={8}>
        <Finish /> <span>Finished</span>
      </Flex>
      <Tile>{`Leaderboard ened ${DateTime.fromISO(date).toFormat("dd.MM.yy")}`}</Tile>
    </TileWrapper>
  );
};

const TileWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;
  color: var(--daylight-daylight-75, rgba(200, 215, 245, 0.75));
  font-family: var(--font-family-golos);
  font-size: 14px;
  font-weight: 500;
  text-transform: uppercase;
`;
const Tile = styled.div`
  border-radius: 8px;
  border: 1px solid var(--daylight-daylight-10, rgba(200, 215, 245, 0.1));
  background: var(--Color-2, #141722);
  text-transform: uppercase;
  padding: 16px;
  color: var(--daylight-daylight-half, rgba(200, 215, 245, 0.5));
  text-align: center;
  text-shadow: 0px 1px 1px rgba(0, 0, 0, 0.25);
  font-family: var(--font-family-golos);
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
`;

export const AnimatedNumber: React.FC<{ number: number }> = ({ number }) => {
  const { animatedNumber } = useSpring({
    from: { animatedNumber: 0 },
    animatedNumber: number,
    delay: 0,
    config: {
      mass: 1,
      tension: 10,
      friction: 100,
      duration: 1500,
    },
  });

  return (
    <animated.b
      className={"number-fade"}
      data-content={animatedNumber.to((n) => formatNumber(+n.toFixed(0)))}
    >
      {animatedNumber.to((n) => formatNumber(+n.toFixed(0)))}
    </animated.b>
  );
};

const FlexWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 1rem;
  margin: 2rem 0;
  position: relative;
`;

const CustomRewards = styled.div`
  /* padding: 10px; */
  font-size: 16px;
  display: flex;
  flex-direction: column;
  /* gap: 6px; */
  align-items: center;
  /* line-height: normal; */
  small {
    font-size: 14px;
  }
  & > span {
    font-size: 28px;
    &::first-letter {
      color: #5ebd3d;
    }
  }
`;

const WrapperBG = styled.div`
  position: relative;
`;

const cssList = css`
  max-width: 1008px;
  align-items: left;
  margin: 0 auto;
`;

const Box = styled.div<{ casinoProvider?: string }>`
  box-sizing: border-box;
  background-position: top center;
  background-repeat: no-repeat;
  background-clip: border-box;
  z-index: 1;
  position: relative;
`;

const BoxItem = styled.div<IStyles>`
  box-sizing: border-box;
  text-align: center;
  margin: 0 1rem;
`;

const ExploreText = styled.div`
  color: #d2eaff;
  font-family: var(--font-family-golos);
  font-size: 18px;
  font-style: italic;
  font-weight: 800;
  line-height: 21px;
  text-align: center;
  text-transform: uppercase;
`;

const Text = styled.div`
  ${$stylesReset}
  color: var(--daylight-daylight-main, #D5E0F5);
  font-family: var(--font-family-golos);
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 0.02em;
  line-height: 150%;
  text-align: center;
`;

const Subtitle = styled(Text)`
  color: var(--daylight-daylight-main, #d5e0f5);
  text-align: center;
  font-family: var(--font-family-exo);
  font-size: 20px;
  font-style: italic;
  font-weight: 800;
  line-height: normal;
  letter-spacing: 0.4px;
  text-shadow: 0px 5px 6px rgba(0, 0, 0, 0.55);
`;

const TextGradient = styled.h1`
  color: var(--daylight-daylight-main, #d5e0f5);
  font-family: var(--font-family-exo);
  text-transform: uppercase;
  font-size: 72px;
  font-style: italic;
  font-weight: 900;
  line-height: normal;
  letter-spacing: 1.44px;
  margin: 0;
  z-index: 1;
  position: relative;

  filter: drop-shadow(0px 1px 6px rgba(0, 0, 0, 1));

  span {
    text-shadow: 0px 1px 6px rgba(0, 0, 0, 0.75);
  }
  & b {
    letter-spacing: 6px;
    position: relative;
    background: linear-gradient(
      130deg,
      #ffce64 27.05%,
      #ffd48e 43.67%,
      #fff 57.81%,
      #ffd48e 73.37%,
      #ffce64 97.77%
    );
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }

  & b::before {
    padding: 0;
    content: attr(data-content);
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
    text-shadow: 0 1px #41362b, 0 2px #3e362b, 0 3px #3b352a, 0 4px #383429, 0 5px #353328,
      0 6px #323227, 0 7px #2f3126, 0 8px #2c3025, 0 9px #292f24, 0 10px #262e23, 0 11px #232d22,
      0 12px #1f2b21, 0 13px #1c2a20, 0 14px #1c1612;
    /* background: linear-gradient(280deg, #2b271e 0%, #6d5f40 100%);
    background-size: 200% 200%;
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-stroke: 8px transparent; */
  }

  @media (max-width: 940px) {
    font-size: 32px;
  }
`;

const TotalPrize = styled(TextGradient)`
  /* color: var(--daylight-daylight-main, #d5e0f5); */
  font-family: var(--font-family-exo);
  font-size: 88px;
  font-style: italic;
  font-weight: 800;
  line-height: normal;

  text-transform: uppercase;
  margin: 0;
  @media (max-width: 940px) {
    font-size: 64px;
  }
`;

// const HowToPlayButton = styled.button`
//   margin: 2.5rem auto 0;
//   padding: 0.875rem 1.5rem;
//   background: #e9f4ff0d;
//   border: 1px solid #e9f4ff0d;
//   text-transform: uppercase;
//   font-family: "Golos Text";
//   font-weight: 600;
//   border-radius: 6px;
//   cursor: pointer;
//   display: flex;
//   align-items: center;
//   gap: 0.5rem;
//   color: #e9f4ffbf;
//   &:hover {
//     opacity: 0.8;
//   }
// `;
