import { Avatar } from "@chakra-ui/react";
import useEmblaCarousel from "embla-carousel-react";
import { DEFAULT_AVATAR } from "helpers/constants";
import { truncateText } from "helpers/truncateText";
import { PropsWithChildren, useCallback, useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { formatNumber } from "./utils/formatNumbers";

const RaffleLeaderboardCarousel: React.FC<PropsWithChildren & { animationQue: number[] }> = ({
  children,
  animationQue,
}) => {
  const [emblaRef, emblaApi] = useEmblaCarousel({ loop: true, skipSnaps: true });
  const rafId = useRef<NodeJS.Timeout | null>(null);
  const currentIndexRef = useRef(0);
  const [finished, setFinished] = useState(false);

  const startAutoScroll = useCallback(() => {
    const scrollInterval = 3000;

    const scroll = () => {
      if (emblaApi && animationQue.length > 0) {
        const index = currentIndexRef.current;

        if (index < animationQue.length) {
          const targetIndex = animationQue[index];
          console.log({ targetIndex, index });
          emblaApi.scrollTo(targetIndex);

          currentIndexRef.current += 1;
        } else {
          setFinished(true);
          currentIndexRef.current = 0;
          stopAutoScroll();
          return;
        }

        rafId.current = setTimeout(() => requestAnimationFrame(scroll), scrollInterval);
      }
    };

    scroll();

    return () => {
      if (rafId.current) {
        clearTimeout(rafId.current)
    };
    };
  }, [emblaApi, animationQue]);

  const stopAutoScroll = useCallback(() => {
    if (rafId.current) {
      clearTimeout(rafId.current);
      rafId.current = null;
    }
  }, []);

  useEffect(() => {
    if (emblaApi) {
      currentIndexRef.current = 0; 
      setFinished(false);
      const stopAutoScrollHandler = startAutoScroll();
      return () => {
        stopAutoScrollHandler()
    };
    }
  }, [emblaApi, animationQue, startAutoScroll]);

  useEffect(() => {
    if (finished) {
      console.log("Animation finished!");
    }
  }, [finished]);

  return (
    <div>
      <RootCar>
        <div className="viewport" ref={emblaRef}>
          <div className="container">{children}</div>
        </div>
      </RootCar>
    </div>
  );
};

const RootCar = styled.div`
  /* overflow: hidden; */
  width: 100%;
  max-width: 900px;
  margin: 0 auto;
  position: relative;

  &::before {
    content: "";
    display: block;
    position: absolute;
    border-radius: 8px;
    z-index: 0;
    inset: -1px;

    background: linear-gradient(180deg, #b48b23 0%, #ffd97a 24%, rgba(0, 0, 0, 0) 100%),
      linear-gradient(180deg, #181e30 0%, rgba(0, 0, 0, 0) 100%);
  }
  .viewport {
    border-radius: 8px;
    overflow: hidden;
    width: 100%;
    padding: 12px 20px;
    box-shadow: 0px 0px 0px 3px #11161e inset;
    background-color: #181e30;
    position: relative;

    &::before {
      content: "";
      position: absolute;
      inset: 0;
      border-radius: 8px;
      z-index: 1;
      background: linear-gradient(
        90deg,
        #181e30 0%,
        rgba(0, 0, 0, 0) 30%,
        rgba(0, 0, 0, 0) 70%,
        #181e30 100%
      );
    }
  }

  .container {
    display: flex;
    gap: 16px;
  }
`;

export const RaffleLeaderboardRandomWinnerCard: React.FC<any> = ({
  prizeNumber,
  displayName,
  prize,
  avatar,
}) => {
  return (
    <Card number={prizeNumber}>
      <CardContent>
        <UserAvatarWrapper>
          <Avatar size="xl" src={avatar ?? DEFAULT_AVATAR} />
        </UserAvatarWrapper>
        <DisplayName>{truncateText(displayName || "UnknownPlayer", 10)}</DisplayName>
        <Prize>
          <b data-content={`$${formatNumber(prize)}`}>${formatNumber(prize)}</b>
        </Prize>
      </CardContent>
      {/* <AnimatedBackground /> */}
      <GradientBorder />
    </Card>
  );
};

const DisplayName = styled.span`
  color: var(--daylight-daylight-main, #d5e0f5);
  font-family: var(--font-family-exo);
  font-size: 20px;
  font-style: italic;
  font-weight: 800;
  line-height: normal;
  text-transform: uppercase;
  text-align: left;
  position: relative;
  filter: drop-shadow(0px 0px 6px #000);
`;

const UserAvatarWrapper = styled.div`
  border: 2px solid #10131a;
  border-radius: 50%;
`;

const Card = styled.div<{ number: number }>`
  position: relative;
  /* min-height: 200px; */
  /* margin: 4px; */
  border-radius: 8px;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  user-select: none;
  min-width: 200px;
`;

const GradientBorder = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  inset: 0;
  /* padding: 1px; */
  z-index: 0;
  border-radius: inherit;

  &::before {
    content: "";
    display: block;
    position: absolute;
    border-radius: 8px;
    z-index: 0;
    inset: -1px;

    background: linear-gradient(180deg, #b48b23 0%, #ffd97a 24%, rgba(0, 0, 0, 0) 100%),
      linear-gradient(180deg, #181e30 0%, rgba(0, 0, 0, 0) 100%);
  }
`;
const CardContent = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 20px;
  padding: 6px;
  z-index: 10;
  border-radius: inherit;
  box-shadow: 0px 0px 0px 3px #11161e inset;
  background-color: #181e30;
  overflow: hidden;
  &::before {
    border-radius: inherit;
    content: "";
    position: absolute;
    inset: 0;
    background: linear-gradient(
      105deg,
      rgba(208, 219, 255, 0) -24.09%,
      rgba(209, 219, 255, 0.02) 47.65%,
      rgba(209, 219, 255, 0.09) 65.58%,
      rgba(215, 224, 255, 0.02) 83.52%,
      rgba(222, 231, 255, 0) 155.26%
    );
    transform: rotate(45deg) scale(1.5);
    /* background-position-x: left; */
    /* animation: glassSpark 6s ease-in-out infinite; */
  }

  @keyframes glassSpark {
    0% {
      transform: rotate(0deg) scale(1);
    }
    50% {
      transform: rotate(45deg) scale(1.5);
      opacity: 0;
    }
    100% {
      transform: rotate(0deg) scale(1);
    }
  }
`;

const Prize = styled.div`
  color: var(--daylight-daylight-main, #d5e0f5);
  font-family: var(--font-family-exo);
  font-size: 24px;
  font-style: italic;
  font-weight: 800;
  line-height: normal;
  letter-spacing: 2px;
  text-transform: uppercase;
  margin: 0;
  @media (max-width: 940px) {
    font-size: 64px;
  }

  & b {
    position: relative;
    background: linear-gradient(
      130deg,
      #ffce64 27.05%,
      #ffd48e 43.67%,
      #fff 57.81%,
      #ffd48e 73.37%,
      #ffce64 97.77%
    );

    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }

  & b::before {
    padding: 0;
    content: attr(data-content);
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
    background: linear-gradient(280deg, #2b271e 0%, #6d5f40 100%);
    background-size: 200% 200%;
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-stroke: 3px transparent;
  }
`;

export default RaffleLeaderboardCarousel;
